#hero {
  background-image: url("/images/home-hero.jpg");
  height: 100vh;
  position: relative;
}

#about {
  background-position: top;
  background-image: url("/images/about-hero.jpg");
  height: 80vh;
}

#songlist {
  background-position: top;
  background-image: url("/images/songlist-hero.jpg");
  height: 100vh;
}

#performances {
  background-position: top;
  background-image: url("/images/performances-hero.jpg");
  height: 90vh;
}

#contact {
  background-position: top;
  background-image: url("/images/contact-hero.jpg");
  height: 90vh;
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

header {
  z-index: 900;
}

.bg-dark {
  --bs-bg-opacity: 0.8;
}

a.photo {
  cursor: pointer;
}

.logo-banner {
  padding: 1.5em 0;
}

.wordmark {
  font-weight: 200;
  font-size: 18px;
}

.welcome-body {
  position: absolute;
  bottom: 0;
  padding: 2em 1em;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 3em;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

h1 {
  color: rgb(228, 4, 4);
  font-weight: 600;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
  color: #888;
  line-height: 1.5em;
  font-weight: 200;
}

.content-col {
  padding: 4em 0;
  text-align: center;
}

.carousel-caption {
  padding: 1em 2em;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.6);

  h5 {
    font-weight: 600;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 1rem;
  background-size: 75%;
}
