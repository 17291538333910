// Placeholder to allow overriding predefined variables smoothly.
// You should be able to override any Bootstrap variable here: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
$enable-shadows: true;
$primary: #ff0000;
$card-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
$font-family-sans-serif: 'Poppins', sans-serif;

$modal-fade-transform:              translate(0, -200px);
//$modal-show-transform:              translate(0, 0);
$modal-transition:                  transform 0.3s ease-out;
